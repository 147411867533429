
import { Vue, Options, mixins, prop } from "vue-class-component";

class VaInputProps {
  areas = prop<string[]>({ type: Array, default: [] });
  ReservedAreaCodes = prop<string[]>({ type: Array, default: [] });
}
const VaInputPropsMixin = Vue.with(VaInputProps);

@Options({
  name: "VaInput",
})
export default class VaInput extends mixins(VaInputPropsMixin) {
  InputValue: string = "";
  InternalAreas: string[] = [];
  ActiveErrorMessages: string[] = [];
  ErrorMessageTimer = -1;
  inFocus: boolean = true;

  focusInput(): void {
    if (typeof this.$refs.input !== "undefined") {
      (this.$refs.input as HTMLInputElement).focus();
    }
  }

  DeleteAreaCode(area: string): void {
    if (this.areas.indexOf(area) > -1) {
      this.areas.splice(this.areas.indexOf(area), 1);
    }
    this.UpdateAreas();
  }

  OnKeyDown(): void {
    let ParsedInputValue = parseInt(this.InputValue.substring(0, 5));

    if (isNaN(ParsedInputValue)) {
      this.InputValue = "";
      return;
    }

    if (this.InputValue.indexOf(",") == 5) {
      while (this.InputValue.indexOf(",") == 5) {
        let ParsedInputValue = parseInt(this.InputValue.substring(0, 5));

        if (!isNaN(ParsedInputValue)) {
          this.InternalAreas.push(
            this.GetValueWithPrefix(
              this.InputValue.substring(0, 5),
              ParsedInputValue,
            ),
          );
          this.InputValue = this.InputValue.substring(6);
        }
      }

      if (this.InputValue.length == 5 && !isNaN(parseInt(this.InputValue))) {
        this.InternalAreas.push(
          this.GetValueWithPrefix(this.InputValue, parseInt(this.InputValue)),
        );
        this.InputValue = "";
      }

      this.UpdateAreas();
      return;
    }

    this.InputValue = this.GetValueWithPrefix(
      this.InputValue,
      ParsedInputValue,
    );
  }

  GetValueWithPrefix(OrgString: string, NewNumber: number): string {
    let ReturnValue = NewNumber.toString();

    let Index = 0;

    if (NewNumber == 0) {
      Index += 1;
    }
    while (Index < OrgString.length && Index < 5 && OrgString[Index] == "0") {
      ReturnValue = "0" + ReturnValue;
      Index++;
    }

    return ReturnValue;
  }

  OnInputEnter(): void {
    if (this.InputValue.length == 5 && !isNaN(parseInt(this.InputValue))) {
      this.InternalAreas.push(this.InputValue);
      this.InputValue = "";

      this.UpdateAreas();
    }
  }

  mounted(): void {
    if (Array.isArray(this.areas)) {
      this.InternalAreas = [...this.areas];
      this.UpdateAreas(false);
    } else {
      this.InternalAreas = [];
    }
  }

  ShowErrorMessage(Message: string): void {
    this.ActiveErrorMessages.push(Message);

    window.setTimeout(() => {
      this.ActiveErrorMessages.splice(0, 1);
    }, 5000);
  }

  OnCustomerSave(): void {
    this.OnInputEnter();
  }

  Empty(): void {
    this.InternalAreas = [];
    this.UpdateAreas();
  }

  UpdateAreas(SetToExternal: boolean = true): void {
    const UniqueAreas: string[] = [];
    for (const area of this.InternalAreas) {
      let ParsedInputValue = parseInt(area);
      if (area.length == 5 && !isNaN(ParsedInputValue)) {
        if (this.ReservedAreaCodes.indexOf(area) != -1) {
          this.ShowErrorMessage(
            `Die PLZ ${area} wurde bereits an einen anderen Handelsvertreter vergeben. Doppelvergaben sind nicht möglich.`,
          );
        } else if (UniqueAreas.indexOf(area) != -1) {
          this.ShowErrorMessage(
            `Die PLZ ${area} wurde bereits eingetragen. Doppelvergaben sind nicht möglich.`,
          );
        } else {
          UniqueAreas.push(area);
        }
      }
    }

    this.InternalAreas = UniqueAreas;

    if (SetToExternal) {
      this.$emit("UpdateAreas", this.InternalAreas);
    }
  }
}
