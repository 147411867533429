import { createApp } from "vue";
import App from "./App.vue";

import MyWmaComponents from "@iib/my-wma-components";
import { injectFontAwesome } from "./inject/fontawesome";
import { injectStore } from "./inject/store";
import { injectAxios } from "./inject/axios";
import "@iib/my-wma-components/dist/my-wma-components.min.css";
import { injectEnv } from "./inject/env";
import { injectMitt } from "./inject/mitt";

const app = createApp(App);
app.use(MyWmaComponents);
injectFontAwesome(app);
injectEnv(app);
injectStore(app);
injectAxios(app);
injectMitt(app);

app.mount("#iib-mywma-admin");
