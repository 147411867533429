
import { VaInput } from "@iib/my-wma-components";
import { Options, Vue } from "vue-class-component";
import ColorDisplayExample from "../../components/ColorDisplayExample.vue";

@Options({
  name: "Settings",
  components: {
    ColorDisplayExample,
  },
})
export default class MyWma extends Vue {
  timestamp: number = 0;
  stepPlaceholder: string[] = [
    "Maklertermin durchführen",
    "Besten Preis festlegen",
    "Vermarktung starten",
    "Den Verkauf feiern",
  ];

  get addStepButtonDisabled(): boolean {
    if (typeof this.$store.state.customer !== "undefined") {
      return this.$store.state.customer?.nextSteps?.length >= 4;
    }
    return true;
  }

  saveColorOverwrite(event: boolean): void {
    this.$store.commit("customer/setColorOverwriteActive", event);
    this.$store.dispatch("customer/save");
  }

  mounted(): void {
    this.timestamp = Date.now();
    Vue.prototype.$mitt.on("fileUploaded", () => {
      this.timestamp = Date.now();
    });
  }

  saveColorValue(newValue: string): void {
    if (typeof newValue === "string" && newValue[0] !== "#") {
      newValue = "#";
      (this.$refs.colorInput as VaInput).setNewValue(newValue);
    } else if (typeof newValue === "string" && newValue.length > 7) {
      newValue = newValue.substring(0, 7);
      (this.$refs.colorInput as VaInput).setNewValue(newValue);
    } else {
      this.$store.commit("customer/setColorValue", newValue);
    }

    this.$store.dispatch("customer/save");
  }

  addStep(): void {
    if (!this.addStepButtonDisabled) {
      this.$store.state.customer?.nextSteps.push("");
      this.$store.dispatch("customer/save");
    }
  }

  removeStep(index: number): void {
    this.$store.state.customer?.nextSteps.splice(index, 1);
    this.$store.dispatch("customer/save");
  }

  saveStep(index: number, value: any): void {
    if (typeof this.$store.state.customer !== "undefined") {
      this.$store.state.customer.nextSteps[index] = value;
    }
    this.$store.dispatch("customer/save");
  }
}
