import { actionParameters, authModuleState } from "@/classes/interfaces";
import createAuth0Client, { Auth0Client } from "@auth0/auth0-spa-js";
import { Vue } from "vue-class-component";

export const authModule = {
  namespaced: true,
  state: (): authModuleState => ({
    isAuthenticated: false,
    auth0client: null,
    isLoaded: false,
    isAdmin: false,
    user: null,
    isUserInvalid: false,
    hasHandelsvertreterRole: false,
  }),
  mutations: {
    setIsAuthenticated(state: authModuleState, value: boolean): void {
      state.isAuthenticated = value;
    },
    setAuth0client(state: authModuleState, value: Auth0Client): void {
      state.auth0client = value;
    },
    setIsLoaded(state: authModuleState, value: boolean): void {
      state.isLoaded = value;
    },
    setIsAdmin(state: authModuleState, value: boolean): void {
      state.isAdmin = value;
    },
    setIsUserInvalid(state: authModuleState, value: boolean): void {
      state.isUserInvalid = value;
    },
    setUser(state: authModuleState, value: any): void {
      state.user = value;
    },
    setHasHandelsvertreterRole(state: authModuleState, value: boolean): void {
      state.hasHandelsvertreterRole = value;
    },
  },
  getters: {
    uuid: (state: authModuleState): string => {
      return (state.user as any)["https://iib-institut.de/app_metadata"]
        .customer;
    },
  },
  actions: {
    async validateAuthentication(
      context: actionParameters<authModuleState>,
    ): Promise<void> {
      const isAuthenticated =
        await context.state.auth0client?.isAuthenticated();
      if (!isAuthenticated) {
        context.state.auth0client?.logout();
      }
    },
    async getToken(
      context: actionParameters<authModuleState>,
    ): Promise<string> {
      await context.dispatch("validateAuthentication");
      const claims = await context.state.auth0client?.getIdTokenClaims();

      if (typeof claims !== "undefined") {
        return claims["__raw"];
      }
      return "";
    },
    async init(context: actionParameters<authModuleState>): Promise<void> {
      const auth0Client = await createAuth0Client({
        domain: ENV_VARS.VUE_APP_DOMAIN_A0,
        client_id: ENV_VARS.VUE_APP_CLIENTID_A0,
        redirect_uri: ENV_VARS.VUE_APP_REDIRECT_A0,
        audience: "https://admin-api.iib-it.de",
      });
      context.commit("setAuth0client", auth0Client);

      // check for existing login
      if (await auth0Client.isAuthenticated()) {
        context.commit("setIsAuthenticated", true);

        const user = await auth0Client.getUser();

        const isIibAdmin =
          (user as any)["https://iib-institut.de/roles"].indexOf(
            "ROLE_WMA4_MYWMA_IIB_ADMIN",
          ) != -1;

        const hasHandelsvertreterRole =
          (user as any)["https://iib-institut.de/roles"].indexOf(
            "ROLE_CUSTOMER_ADMIN_HANDELSVERTRETER",
          ) != -1;

        const isCustomerAdmin =
          (user as any)["https://iib-institut.de/roles"].indexOf(
            "ROLE_WMA4_MYWMA_CUSTOMER_ADMIN",
          ) != -1;

        context.commit("setHasHandelsvertreterRole", hasHandelsvertreterRole);

        if (isIibAdmin === false && isCustomerAdmin === false) {
          // no valid role
          context.commit("setIsAuthenticated", false);
          context.commit("setIsUserInvalid", true);
          context.commit("setIsLoaded", true);

          return;
        }

        if (isIibAdmin) {
          context.commit("setIsAdmin", true);
        }

        context.commit("setUser", user);
      }

      // check for login redireczt
      if (
        window.location.search.includes("code=") &&
        window.location.search.includes("state=")
      ) {
        try {
          await auth0Client.handleRedirectCallback();
          location.href = "/";
        } catch (e) {
          // login not possible
          console.error(e);
        }
      }

      context.commit("setIsLoaded", true);
    },
    logout(context: actionParameters<authModuleState>): boolean {
      context.state.auth0client?.logout();
      return true;
    },
    async login(context: actionParameters<authModuleState>): Promise<boolean> {
      context.state.auth0client?.loginWithRedirect();
      return true;
    },
  },
};
