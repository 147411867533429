import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0d02a994"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "plz-input-wrap" }
const _hoisted_2 = { class: "inner-input" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 0,
  class: "plz-input-error"
}
const _hoisted_5 = ["Key"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["plz-input", { 'in-focus': _ctx.inFocus }])
    }, [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.InternalAreas, (area) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "chip",
            key: area
          }, [
            _createElementVNode("div", {
              class: "delete",
              onClick: ($event: any) => (_ctx.DeleteAreaCode(area))
            }, [
              _createVNode(_component_font_awesome_icon, { icon: ['fas', 'times'] })
            ], 8, _hoisted_3),
            _createTextVNode(" " + _toDisplayString(area), 1)
          ]))
        }), 128)),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.InputValue) = $event)),
          onKeyup: [
            _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.OnKeyDown && _ctx.OnKeyDown(...args))),
            _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => (_ctx.OnInputEnter && _ctx.OnInputEnter(...args)), ["enter"]))
          ],
          onFocus: _cache[3] || (_cache[3] = ($event: any) => (_ctx.inFocus = true)),
          placeholder: "Eingabe",
          onBlur: _cache[4] || (_cache[4] = ($event: any) => (_ctx.inFocus = false)),
          ref: "input"
        }, null, 544), [
          [_vModelText, _ctx.InputValue]
        ])
      ]),
      _createElementVNode("div", {
        class: "plz-label",
        onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.focusInput && _ctx.focusInput(...args)))
      }, "Postleitzahl Bereiche")
    ], 2),
    (_ctx.ActiveErrorMessages.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ActiveErrorMessages, (Message) => {
            return (_openBlock(), _createElementBlock("div", { Key: Message }, _toDisplayString(Message), 9, _hoisted_5))
          }), 256))
        ]))
      : _createCommentVNode("", true)
  ]))
}