
import { Options, Vue } from "vue-class-component";

@Options({
  name: "iFrameSettings",
})
export default class iFrameSettings extends Vue {
  get url(): string {
    return `${Vue.prototype.$ENV_VARS.VUE_APP_IFRAME_DOMAIN}/?contract_id=${this.$store.getters["customer/activeContractId"]}&customer_id=${this.$store.getters["customer/customerId"]}`;
  }

  get code(): string {
    return `<iframe
    style="width:100%;height:100%;border:0px;"
    src="${this.url}"
></iframe>`;
  }

  get hasMultipleContracts(): boolean {
    if (typeof this.$store.state.customer === "undefined") {
      return false;
    }
    return this.$store.state.customer.contracts.length > 1;
  }

  showPreview(): void {
    window.open(this.url, "_blank");
  }

  copyCode(): void {
    navigator.clipboard.writeText(this.code);
    Vue.prototype.$mitt.emit("showMessage", {
      text: "Der Code wurde in die Zwischenablage kopiert.",
      type: "success",
      duration: 3,
    });
  }
}
