import { viewModuleState } from "@/classes/interfaces";

export const viewModule = {
  namespaced: true,
  state: (): viewModuleState => ({
    navigationOpen: false,
    activeContract: 0,
    loaded: false,
    error: "",
    activeAdminCustomer: 0,
  }),
  mutations: {
    setNavigationState(state: viewModuleState, value: boolean): void {
      state.navigationOpen = value;
    },
    setActiveContract(state: viewModuleState, value: number): void {
      state.activeContract = value;
    },
    setLoaded(state: viewModuleState, value: boolean): void {
      state.loaded = value;
    },
    setError(state: viewModuleState, value: string): void {
      state.error = value;
    },
    setActiveAdminCustomer(state: viewModuleState, value: number): void {
      state.activeAdminCustomer = value;
    },
  },
  actions: {},
};
