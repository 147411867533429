
import { Options, Vue } from "vue-class-component";

@Options({
  name: "Pdf",
})
export default class Pdf extends Vue {
  timestamp: number = 0;
  previewVisible: boolean = false;
  scale: number = 1;
  width: number = 780;
  height: number = 780;
  previewPageIndex = 1;
  totalPageCount = 17;
  iframeInitInterval: number | null = null;

  mounted(): void {
    this.timestamp = Date.now();
    Vue.prototype.$mitt.on("fileUploaded", () => {
      this.timestamp = Date.now();
    });

    this.calculateScale();

    window.onresize = () => {
      this.calculateScale();
    };

    window.onmessage = (e: any) => {
      if (typeof e.data === "object" && typeof e.data.pagecount === "number") {
        this.totalPageCount = e.data.pagecount;
      }
      if (this.iframeInitInterval !== null) {
        clearInterval(this.iframeInitInterval);
      }
    };

    this.$forceUpdate;
  }

  calculateScale(): void {
    const ASPECT_RATIO = 0.707113562;

    if ((window.innerWidth - 150) / (window.innerHeight - 250) < ASPECT_RATIO) {
      this.width = window.innerWidth - 150;
      this.height = this.width / ASPECT_RATIO;
    } else {
      this.height = window.innerHeight - 250;
      this.width = this.height * ASPECT_RATIO;
    }

    this.scale = this.height / 1838;
  }

  showPreview(): void {
    this.previewVisible = true;
    this.ScrollPreview(1, true);

    this.iframeInitInterval = setInterval(() => {
      this.initPdfPreview();
    }, 1000);
  }

  initPdfPreview(): void {
    if (
      typeof this.$refs["iframe"] !== "undefined" &&
      this.$refs["iframe"] !== null
    ) {
      (this.$refs.iframe as any).contentWindow?.postMessage(
        { init: true, origin: location.origin },
        this.$ENV_VARS.VUE_APP_DOMAIN_API,
      );
    }
  }

  hidePreview(): void {
    this.previewVisible = false;
  }

  ScrollPreview(value: number, override = false): void {
    if (override) {
      this.previewPageIndex = value;
    } else {
      this.previewPageIndex += value;
    }

    this.previewPageIndex = Math.max(this.previewPageIndex, 1);
    this.previewPageIndex = Math.min(
      this.previewPageIndex,
      this.totalPageCount,
    );
    if (
      typeof this.$refs.iframe !== "undefined" &&
      this.$refs["iframe"] !== null
    ) {
      (this.$refs.iframe as any).contentWindow.postMessage(
        { newPageIndex: this.previewPageIndex },
        this.$ENV_VARS.VUE_APP_DOMAIN_API,
      );
    } else {
      setTimeout(() => {
        this.ScrollPreview(value, override);
      }, 150);
    }
  }
}
