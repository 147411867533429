import { Vue } from "vue-class-component";
import { App } from "vue";

if (process.env.NODE_ENV === "development") {
  Object.keys(process.env)
    .filter((key) => key.indexOf("VUE_") == 0)
    .forEach((key) => {
      (ENV_VARS as any)[key] = process.env[key];
    });
}

export const injectEnv = (app: App): void => {
  app.config.globalProperties.$ENV_VARS = ENV_VARS;
  Vue.prototype.$ENV_VARS = ENV_VARS;
};
