
import { Options, Vue } from "vue-class-component";
import MyWma from "./settings/MyWma.vue";
import Pdf from "./settings/Pdf.vue";
import IframeSettings from "./settings/Iframe.vue";
import PersonView from "./settings/Persons.vue";
import { customerModuleState } from "@/classes/interfaces";

@Options({
  name: "Settings",
  components: {
    MyWma,
    Pdf,
    IframeSettings,
    PersonView,
  },
})
export default class Settings extends Vue {
  activePage = "mywma";
  DomainApi = this.$ENV_VARS.VUE_APP_DOMAIN_API;
  contractListOpen = false;
  customerOverlayOpen = false;
  adminCustumerNumber = "";
  adminLoadError = false;
  iframeVisible = false;
  personsVisible = true;
  navigationItems = [
    {
      key: "mywma",
      icon: "tablet",
      title: "Dashboard",
      click: (): void => {
        this.activePage = "mywma";
        this.$store.commit("view/setNavigationState", false);
      },
    },
    {
      key: "pdf",
      icon: "file-pdf",
      title: "PDF",
      click: (): void => {
        this.activePage = "pdf";
        this.$store.commit("view/setNavigationState", false);
      },
    },
    {
      key: "iframe",
      icon: "code",
      title: "iFrame",
      click: (): void => {
        this.activePage = "iframe";
        this.$store.commit("view/setNavigationState", false);
      },
    },
    {
      key: "persons",
      icon: "users",
      title: "Handelsvertreter",
      click: (): void => {
        this.activePage = "persons";
        this.$store.commit("view/setNavigationState", false);
      },
    },
  ];
  bottomNavigationItems = [
    {
      key: "signout",
      icon: "sign-out-alt",
      title: "Abmelden",
      click: (): void => {
        this.$store.dispatch("auth/logout");
      },
    },
  ];

  get visibleNavigationItems(): any[] {
    const items = [...this.navigationItems];

    const activeContact = this.$store.getters["customer/activeContract"];

    if (
      activeContact?.iFrameConfig?.iFrameConfig?.use_commercial_agent ===
        true &&
      this.$store.state.auth?.hasHandelsvertreterRole
    ) {
      // show handelsverterter
    } else {
      items.splice(3, 1);
    }

    if (!this.iframeVisible) {
      items.splice(2, 1);
    }

    return items;
  }

  get hasMultipleContracts(): boolean {
    if (typeof this.$store.state.customer?.contracts !== "undefined") {
      return this.$store.state.customer?.contracts.length > 1;
    }
    return false;
  }

  async mounted(): Promise<void> {
    if (this.$store.state.auth?.isAdmin) {
      this.adminResetCustomer();
    } else {
      await this.$store.dispatch("customer/load");
      this.iframeVisible = this.$store.getters["customer/iframePageVisible"];
    }
  }

  async deleteFile(type: string): Promise<void> {
    await this.$store.dispatch("customer/deleteFile", {
      type,
    });

    switch (type) {
      case "image":
        this.$store.commit("customer/setImage", "");
        break;
      case "pdfTitleImage":
        this.$store.commit("customer/setPdfTitleImage", "");
        break;
      case "pdfEndImage":
        this.$store.commit("customer/setPdfEndImage", "");
        break;
      case "pdfLogo":
        this.$store.commit("customer/setPdfLogo", "");
        break;
      case "mywmaLogo":
        this.$store.commit("customer/setMywmaLogo", "");
        break;
      case "pdfPage1":
        this.$store.commit("customer/setPdfPage1", "");
        break;
      case "pdfPage2":
        this.$store.commit("customer/setPdfPage2", "");
        break;
    }

    this.$store.dispatch("customer/save");
  }

  async Upload(file: File, type: string): Promise<void> {
    const response = await this.$store.dispatch("customer/uploadFile", {
      file,
      type,
    });

    if (response?.error !== false) {
      switch (response?.error) {
        case 200:
          Vue.prototype.$mitt.emit("showMessage", {
            text: "Die Datei entspricht nicht der angegebenen Größe.",
            type: "error",
            duration: 3,
          });
          break;
        case 413:
          Vue.prototype.$mitt.emit("showMessage", {
            text: "Die Datei ist größer als 10 MB.",
            type: "error",
            duration: 3,
          });
          break;
        default:
          Vue.prototype.$mitt.emit("showMessage", {
            text: "Die Datei konnte nicht hochgeladen werden.",
            type: "error",
            duration: 3,
          });
          break;
      }
      return;
    }

    Vue.prototype.$mitt.emit("showMessage", {
      text: "Die Datei wurde erfolgreich hochgeladen.",
      type: "success",
      duration: 3,
    });
    switch (type) {
      case "image":
        this.$store.commit("customer/setImage", response.filename);
        break;
      case "pdfTitleImage":
        this.$store.commit("customer/setPdfTitleImage", response.filename);
        break;
      case "pdfEndImage":
        this.$store.commit("customer/setPdfEndImage", response.filename);
        break;
      case "pdfLogo":
        this.$store.commit("customer/setPdfLogo", response.filename);
        break;
      case "mywmaLogo":
        this.$store.commit("customer/setMywmaLogo", response.filename);
        break;
      case "pdfPage1":
        this.$store.commit("customer/setPdfPage1", response.filename);
        break;
      case "pdfPage2":
        this.$store.commit("customer/setPdfPage2", response.filename);
        break;
    }

    Vue.prototype.$mitt.emit("fileUploaded");

    this.$store.dispatch("customer/save");
  }

  async selectContract(index: number): Promise<void> {
    this.$store.commit("view/setActiveContract", index);
    this.contractListOpen = false;
    await this.$store.dispatch("customer/loadContractData");
    this.iframeVisible = this.$store.getters["customer/iframePageVisible"];
  }

  adminResetCustomer(): void {
    this.adminCustumerNumber = "";
    this.$store.commit("view/setActiveAdminCustomer", 0);
    this.customerOverlayOpen = true;
  }

  async adminChangeCustomer(): Promise<void> {
    this.customerOverlayOpen = false;
    this.$store.commit(
      "view/setActiveAdminCustomer",
      parseInt(this.adminCustumerNumber),
    );

    const success = await this.$store.dispatch("customer/loadAdmin");

    if (!success) {
      this.$store.commit("view/setActiveAdminCustomer", 0);
      this.customerOverlayOpen = true;
      this.adminLoadError = true;
    } else {
      this.adminLoadError = false;
      this.$store.commit("view/setActiveContract", 0);
      await this.$store.dispatch("customer/loadContractData");

      this.iframeVisible = this.$store.getters["customer/iframePageVisible"];

      if (!this.personsVisible && this.activePage == "persons") {
        this.activePage = "mywma";
      }
      if (!this.iframeVisible && this.activePage == "iframe") {
        this.activePage = "mywma";
      }
    }
  }
}
