
import { Options, Vue } from "vue-class-component";
import Login from "@/views/Login.vue";
import Settings from "@/views/Settings.vue";

import { VaAlert } from "@iib/my-wma-components";
@Options({
  components: {
    Login,
    Settings,
    VaTest: VaAlert,
  },
})
export default class Home extends Vue {
  cssVars = "--primary-color: #004c4c; --primary-color-rgb: 0,76,76;";

  mounted(): void {
    Vue.prototype.$mitt.on(
      "showMessage",
      (props: {
        text: string;
        type: "success" | "error" | "info";
        duration: number;
      }) => {
        (this.$refs.alertSystem as VaAlert).ShowAlert(
          props.text,
          props.type,
          props.duration * 1000,
        );
      },
    );
  }
}
