import { InjectionKey } from "vue";
import { createStore, Store } from "vuex";
import { authModule } from "./modules/auth";
import { viewModule } from "./modules/view";
import { customerModule } from "./modules/customer";
import {
  authModuleState,
  customerModuleState,
  viewModuleState,
} from "@/classes/interfaces";

// store.ts

// define your typings for the store state
export interface State {
  auth?: authModuleState;
  view?: viewModuleState;
  customer?: customerModuleState;
}

// define injection key
export const key: InjectionKey<Store<State>> = Symbol();

export const store = createStore<State>({
  state: {},
  modules: {
    auth: authModule,
    view: viewModule,
    customer: customerModule,
  },
});
