
import { Vue } from "vue-class-component";

export default class Login extends Vue {
  title = "Willkommen zurück!";
  description =
    "Individualisieren Sie Ihr Wohnmarktanalyse Dashboard und PDF, um sich Ihren Kunden vorzustellen.";

  leftColumnImages = {
    logo: "https://s3.eu-central-1.amazonaws.com/iibinstitut.de-web-assets/mywma/application-title-logo.png",
    mainGraphic:
      "https://s3.eu-central-1.amazonaws.com/iibinstitut.de-web-assets/mywma/middle-graphic.png",
  };

  rightColumnImages = {
    iibLogo:
      "https://s3.eu-central-1.amazonaws.com/iibinstitut.de-web-assets/common/login-form-iib-logo.png",
  };

  async startLogin(): Promise<void> {
    this.$store.dispatch("auth/login");
  }
}
