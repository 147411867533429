
import { Options, Vue } from "vue-class-component";
import "vue-good-table-next/dist/vue-good-table-next.css";
import { VueGoodTable } from "vue-good-table-next";
import { CustomerData } from "@/classes/interfaces";
import PLZInput from "@/components/PLZInput.vue";
import dayjs from "dayjs";

@Options({
  name: "PersonView",
  components: {
    VueGoodTable,
    PLZInput,
  },
})
export default class PersonView extends Vue {
  currentPageIndex = 0;
  itemsPerPage = 15;
  sortingColumn = "id";
  sortingDirection: "asc" | "desc" = "desc";
  loading: boolean = false;
  activeCustomer: CustomerData | null = null;
  activeCustomerOriginal: CustomerData | null = null;
  customerToDelete: CustomerData | null = null;

  EmptyPlzOverlayOpen: boolean = false;

  activeNotificationText: string = "Ola";
  activeNotificationType: "success" | "error" = "error";

  notificationActive = false;
  notificationTimeout = -1;

  showErrors = false;
  errorFields: string[] = [];

  SearchValue: string = "";

  getPageCount(): number {
    return Math.ceil(this.customerData.length / this.itemsPerPage);
  }
  getIsNewCustomer(): boolean {
    if (this.activeCustomer == null) {
      return false;
    }
    return this.activeCustomer.id === "";
  }
  getCustomerId(): string {
    return this.$store.getters["customer/customerId"];
  }

  GetAreaCodesText(customer: CustomerData): string {
    if (customer.areas == null) {
      return "-";
    } else if (Array.isArray(customer.areas?.postal_codes)) {
      return customer.areas.postal_codes.join(", ");
    }
    return "-";
  }

  ChangeAreaCode(NewValue: string, index: number): void {
    if (this.activeCustomer?.areas != null) {
      this.activeCustomer.areas.postal_codes[index] = NewValue;
    }
  }

  ShowNotification(text: string, type: "success" | "error"): void {
    this.activeNotificationText = text;
    this.activeNotificationType = type;
    this.notificationActive = true;

    if (this.notificationTimeout != -1) {
      clearTimeout(this.notificationTimeout);
    }
    this.notificationTimeout = setTimeout(() => {
      this.notificationActive = false;
    }, 7000);
  }

  ValidateInput(CustomerToValidate: CustomerData): boolean {
    this.errorFields = [];

    if (CustomerToValidate.companyName == "") {
      this.errorFields.push("companyName");
    }
    if (CustomerToValidate.city == "") {
      this.errorFields.push("city");
    }
    if (CustomerToValidate.houseNumber == "") {
      this.errorFields.push("houseNumber");
    }
    if (CustomerToValidate.street == "") {
      this.errorFields.push("street");
    }
    if (CustomerToValidate.zipcode == "") {
      this.errorFields.push("zipcode");
    }

    if (
      CustomerToValidate.areas == null ||
      CustomerToValidate.areas.postal_codes.length == 0
    ) {
      this.errorFields.push("areas");
    }

    if (CustomerToValidate.contactData.salutation == "") {
      this.errorFields.push("contactData.salutation");
    }
    if (CustomerToValidate.contactData.surname == "") {
      this.errorFields.push("contactData.surname");
    }
    if (CustomerToValidate.contactData.name == "") {
      this.errorFields.push("contactData.name");
    }
    if (
      CustomerToValidate.contactData.email == "" ||
      CustomerToValidate.contactData.email == null ||
      !/\S+@\S+\.\S+/.test(CustomerToValidate.contactData.email)
    ) {
      this.errorFields.push("contactData.email");
    }
    if (
      CustomerToValidate.contactData.ccEmail != "" &&
      CustomerToValidate.contactData.ccEmail != null
    ) {
      // remove spaces
      CustomerToValidate.contactData.ccEmail =
        CustomerToValidate.contactData.ccEmail.replaceAll(" ", "");

      const CCEmails = CustomerToValidate.contactData.ccEmail.split(",");

      for (const Email of CCEmails) {
        if (!/\S+@\S+\.\S+/.test(Email)) {
          this.errorFields.push("contactData.ccEmail");
          break;
        }
      }
    }
    if (
      CustomerToValidate.contactData.phone == "" ||
      typeof CustomerToValidate.contactData.phone !== "string" ||
      CustomerToValidate.contactData.phone == null
    ) {
      this.errorFields.push("contactData.phone");
    }

    return this.errorFields.length == 0;
  }

  getPageItems(): CustomerData[] {
    return this.customerData
      .sort((a: any, b: any) => {
        let SortingValueA;
        let SortingValueB;
        if (
          this.sortingColumn == "surname" ||
          this.sortingColumn == "name" ||
          this.sortingColumn == "email"
        ) {
          if (
            a.contactData != null &&
            a.contactData[this.sortingColumn] !== null
          ) {
            SortingValueA = a.contactData[this.sortingColumn];
          } else {
            SortingValueA = "";
          }
          if (
            b.contactData != null &&
            b.contactData[this.sortingColumn] !== null
          ) {
            SortingValueB = b.contactData[this.sortingColumn];
          } else {
            SortingValueB = "";
          }
        } else {
          SortingValueA = a[this.sortingColumn];
          SortingValueB = b[this.sortingColumn];
        }
        switch (this.sortingColumn) {
          case "companyName":
          case "surname":
          case "name":
          case "email":
            SortingValueA = SortingValueA.toUpperCase();
            SortingValueB = SortingValueB.toUpperCase();
            break;
          case "id":
            SortingValueA = parseInt(SortingValueA);
            SortingValueB = parseInt(SortingValueB);
            break;
        }
        if (this.sortingDirection == "asc") {
          if (SortingValueA < SortingValueB) return -1;
          if (SortingValueA > SortingValueB) return 1;
          return 0;
        } else {
          if (SortingValueB < SortingValueA) return -1;
          if (SortingValueB > SortingValueA) return 1;
          return 0;
        }
      })
      .filter((value) => {
        if (this.SearchValue == "") {
          return true;
        } else {
          return (
            value.companyName
              .toLowerCase()
              .indexOf(this.SearchValue.toLowerCase()) > -1 ||
            value.id.toLowerCase().indexOf(this.SearchValue.toLowerCase()) >
              -1 ||
            (typeof value.street === "string" &&
              value.street
                .toLowerCase()
                .indexOf(this.SearchValue.toLowerCase()) > -1) ||
            (typeof value.city === "string" &&
              value.city.toLowerCase().indexOf(this.SearchValue.toLowerCase()) >
                -1) ||
            (typeof value.zipcode === "string" &&
              value.zipcode
                .toLowerCase()
                .indexOf(this.SearchValue.toLowerCase()) > -1) ||
            (typeof value.contactData === "object" &&
              typeof value.contactData.name === "string" &&
              value.contactData.name
                .toLowerCase()
                .indexOf(this.SearchValue.toLowerCase()) > -1) ||
            (typeof value.contactData === "object" &&
              typeof value.contactData.surname === "string" &&
              value.contactData.surname
                .toLowerCase()
                .indexOf(this.SearchValue.toLowerCase()) > -1) ||
            (value.areas != null &&
              value.areas.postal_codes
                .join(" ")
                .indexOf(this.SearchValue.toLowerCase()) > -1)
          );
        }
      })
      .filter((value, index) => {
        return (
          index >= this.currentPageIndex * this.itemsPerPage &&
          index < (this.currentPageIndex + 1) * this.itemsPerPage
        );
      });
  }

  customerData: CustomerData[] = [];
  token: string = "";

  async mounted(): Promise<void> {
    this.token =
      (await this.$store.state.auth?.auth0client?.getTokenSilently()) as string;

    if (location.href.indexOf("localhost") > -1) {
      this.token = Vue.prototype.$ENV_VARS.VUE_APP_DEBUG_TOKEN;
    }
    this.LoadData();
  }

  async LoadData(): Promise<void> {
    this.loading = true;

    let newCustomerItems: CustomerData[] = [];

    let numberOfPages = 1;

    let currentPage = 1;
    while (currentPage <= numberOfPages && currentPage < 100) {
      const CustomersResponse = await Vue.prototype.$axios(
        `https://admin-api.iib-it.de/1.0/customers/customers?filter[parentId]=${this.getCustomerId()}&page=${currentPage}`,
        {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        },
      );

      newCustomerItems = [...newCustomerItems, ...CustomersResponse.data.items];
      numberOfPages = CustomersResponse.data.numberOfPages;
      currentPage += 1;
    }

    // validate areas
    for (let Customer of newCustomerItems) {
      if (typeof Customer.areas !== "object") {
        Customer.areas = null;
      }
    }

    // load contact persons - run 8 requests in parrallel
    const ParallelRequestCount = 8;
    for (
      let Index = 0;
      Index < Math.ceil(newCustomerItems.length / ParallelRequestCount);
      Index++
    ) {
      const Promises: Promise<any>[] = [];

      for (
        let ElementIndex = Index * ParallelRequestCount;
        ElementIndex <
        Math.min((Index + 1) * ParallelRequestCount, newCustomerItems.length);
        ElementIndex++
      ) {
        Promises.push(
          this.LoadCustomerContactData(newCustomerItems[ElementIndex].id),
        );
      }

      const ContactResults = await Promise.all(Promises);

      let ResultIndex = 0;
      for (
        let ElementIndex = Index * ParallelRequestCount;
        ElementIndex <
        Math.min((Index + 1) * ParallelRequestCount, newCustomerItems.length);
        ElementIndex++
      ) {
        newCustomerItems[ElementIndex].contactData =
          ContactResults[ResultIndex];

        ResultIndex++;
      }
    }

    this.customerData = newCustomerItems;
    this.loading = false;
  }

  EditCustomer(customerToEdit: CustomerData): void {
    this.showErrors = false;
    this.errorFields = [];
    this.activeCustomer = JSON.parse(JSON.stringify(customerToEdit));
    this.activeCustomerOriginal = JSON.parse(JSON.stringify(customerToEdit));
  }

  async LoadCustomerContactData(Id: string): Promise<any> {
    const ContactResponse = await Vue.prototype.$axios(
      `https://admin-api.iib-it.de/1.0/customers/customers/${Id}/contacts`,
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );

    if (ContactResponse.data.items.length > 0) {
      return ContactResponse.data.items[0];
    } else {
      return {
        assignments: [],
        createdAt: "",
        deletedAt: "",
        email: "",
        id: "",
        position: "",
        salutation: "",
        surname: "",
        updatedAt: "",
        phone: "",
        name: "",
      };
    }
  }

  async SaveActiveCustomer(): Promise<void> {
    if (this.activeCustomer == null) return;

    if (typeof (this.$refs["PLZInputRef"] as any) !== "undefined") {
      (this.$refs["PLZInputRef"] as any).OnCustomerSave();
    }
    this.showErrors = true;
    if (!this.ValidateInput(this.activeCustomer)) return;

    const SaveSuccess = await this.SaveCustomerData(this.activeCustomer);

    if (SaveSuccess == false) {
      this.ShowNotification(
        "Der Handelsvertreter konnte nicht gespeichert werden.",
        "error",
      );
      return;
    }

    if (
      JSON.stringify(this.activeCustomer.contactData) !=
      JSON.stringify(this.activeCustomerOriginal?.contactData)
    ) {
      const ContractDataToSave: any = { ...this.activeCustomer.contactData };

      try {
        const SaveContractResponse = await Vue.prototype.$axios(
          `https://admin-api.iib-it.de/1.0/customers/customers/${this.activeCustomer.id}/contacts/${this.activeCustomer.contactData.id}`,
          {
            method: "PATCH",
            data: ContractDataToSave,
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          },
        );

        if (SaveContractResponse.status !== 200) {
          this.ShowNotification(
            "Der Handelsvertreter konnte nicht gespeichert werden.",
            "error",
          );
          return;
        }
      } catch (error) {
        this.ShowNotification(
          "Der Handelsvertreter konnte nicht vollständig gespeichert werden.",
          "error",
        );
        return;
      }
    }

    this.ShowNotification(
      "Der Handelsvertreter wurde erfolgreich gespeichert.",
      "success",
    );

    const IndexToUpdate = this.customerData.findIndex(
      (customer) => customer.id == this.activeCustomer?.id,
    );

    if (IndexToUpdate != -1) {
      this.activeCustomer.updatedAt = dayjs().toISOString();
      this.customerData[IndexToUpdate] = JSON.parse(
        JSON.stringify(this.activeCustomer),
      );
      this.activeCustomer = null;
    } else {
      this.activeCustomer = null;
      this.LoadData();
    }
  }

  async SaveCustomerData(Customer: CustomerData): Promise<boolean> {
    const Id = Customer.id;
    const CustomerDataToSave: any = { ...Customer };
    CustomerDataToSave.parentId = parseInt(CustomerDataToSave.parentId);
    CustomerDataToSave.type = "commercialAgent";

    CustomerDataToSave.billingHouseNumber = null;
    delete CustomerDataToSave.contactData;
    delete CustomerDataToSave.createdAt;
    delete CustomerDataToSave.id;
    delete CustomerDataToSave.updatedAt;
    delete CustomerDataToSave.deletedAt;

    const SaveCustomerResponse = await Vue.prototype.$axios(
      `https://admin-api.iib-it.de/1.0/customers/customers/${Id}`,
      {
        method: "PATCH",
        data: CustomerDataToSave,
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );

    return SaveCustomerResponse.status === 200;
  }

  async DeleteCustomer(): Promise<void> {
    if (this.customerToDelete == null) return;

    // set active to false
    this.customerToDelete.active = false;
    const SaveSuccess = await this.SaveCustomerData(this.customerToDelete);

    if (SaveSuccess == false) {
      this.ShowNotification(
        "Der Handelsvertreter konnte nicht gelöscht werden.",
        "error",
      );
      return;
    }

    // delete contact
    const DeleteContactResponse = await Vue.prototype.$axios(
      `https://admin-api.iib-it.de/1.0/customers/customers/${this.customerToDelete.id}/contacts/${this.customerToDelete.contactData.id}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );

    if (DeleteContactResponse.status !== 200) {
      this.ShowNotification(
        "Der Handelsvertreter konnte nicht gelöscht werden.",
        "error",
      );
      return;
    }

    // delete customer
    const DeleteCustomerResponse = await Vue.prototype.$axios(
      `https://admin-api.iib-it.de/1.0/customers/customers/${this.customerToDelete.id}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );

    if (DeleteCustomerResponse.status !== 200) {
      this.ShowNotification(
        "Der Handelsvertreter konnte nicht gelöscht werden.",
        "error",
      );
      return;
    }

    this.ShowNotification(
      "Der Handelsvertreter wurde erfolgreich gelöscht.",
      "success",
    );
    this.customerToDelete = null;
    this.LoadData();
  }

  async SaveNewCustomer(): Promise<void> {
    if (this.activeCustomer == null) return;

    this.showErrors = true;
    if (!this.ValidateInput(this.activeCustomer)) return;

    const CustomerDataToSave: any = { ...this.activeCustomer };
    CustomerDataToSave.parentId = parseInt(CustomerDataToSave.parentId);
    CustomerDataToSave.type = "commercialAgent"; // TODO Not saving
    CustomerDataToSave.billingHouseNumber = null;
    delete CustomerDataToSave.contactData;
    delete CustomerDataToSave.createdAt;
    delete CustomerDataToSave.id;
    delete CustomerDataToSave.updatedAt;
    delete CustomerDataToSave.deletedAt;

    const SaveCustomerResponse = await Vue.prototype.$axios(
      `https://admin-api.iib-it.de/1.0/customers/customers`,
      {
        method: "POST",
        data: CustomerDataToSave,
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );

    if (SaveCustomerResponse.status !== 201) {
      this.ShowNotification(
        "Der Handelsvertreter konnte nicht erstellt werden.",
        "error",
      );
      return;
    }

    const NewCustomerId = SaveCustomerResponse.data.id;

    // save type
    await Vue.prototype.$axios(
      `https://admin-api.iib-it.de/1.0/customers/customers/${NewCustomerId}`,
      {
        method: "PATCH",
        data: CustomerDataToSave,
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );

    const ContactDataToSave: any = { ...this.activeCustomer.contactData };

    const SaveContactResponse = await Vue.prototype.$axios(
      `https://admin-api.iib-it.de/1.0/customers/customers/${NewCustomerId}/contacts`,
      {
        method: "POST",
        data: ContactDataToSave,
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );

    if (SaveContactResponse.status !== 201) {
      this.ShowNotification(
        "Der Handelsvertreter konnte nicht erstellt werden.",
        "error",
      );
      return;
    }

    this.activeCustomer = null;
    this.ShowNotification(
      "Der Handelsvertreter wurde erfolgreich erstellt.",
      "success",
    );
    this.LoadData();
  }

  CreateNewEmptyCustomer(): void {
    this.activeCustomer = {
      active: true,
      areas: null,
      bankcode: null,
      billingCity: "",
      billingHouseNumber: "",
      billingStreet: "",
      billingZipcode: "",
      city: "",
      companyName: "",
      contactData: {
        assignments: [],
        createdAt: "",
        deletedAt: "",
        email: "",
        ccEmail: "",
        id: "",
        position: "",
        salutation: "",
        surname: "",
        updatedAt: "",
        phone: "",
        name: "",
      },
      createdAt: "",
      customPermissionText: null,
      dataProtectionUrl: null,
      deletedAt: null,
      homepageUrl: "",
      houseNumber: "",
      id: "",
      iFrameConfig: null,
      imprintUrl: "",
      internalAccountingId: "",
      parentId: this.getCustomerId(),
      street: "",
      type: "",
      uid: "handelsvertreter-" + new Date().getTime(),
      updatedAt: "",
      zipcode: "",
    };

    this.showErrors = false;
    this.errorFields = [];
  }

  EmptyPlz(): void {
    if (this.activeCustomer == null) return;

    if (typeof (this.$refs["PLZInputRef"] as any) !== "undefined") {
      (this.$refs["PLZInputRef"] as any).Empty();
    }

    this.EmptyPlzOverlayOpen = false;
  }

  GetReservedAreaCodes(): string[] {
    let AreaCodes: string[] = [];
    for (const Customer of this.customerData) {
      if (Customer.id != this.activeCustomer?.id) {
        if (
          Customer.areas !== null &&
          Array.isArray(Customer.areas?.postal_codes)
        ) {
          AreaCodes = [...AreaCodes, ...Customer.areas.postal_codes];
        }
      }
    }

    return AreaCodes;
  }

  OnAreasUpdate(NewAreas: string[]): void {
    if (this.activeCustomer == null) return;

    if (this.activeCustomer.areas == null) {
      this.activeCustomer.areas = { postal_codes: [] };
    }

    this.activeCustomer.areas.postal_codes = NewAreas;
  }

  ChangePageCount(diff: number): void {
    this.currentPageIndex = Math.max(
      0,
      Math.min(this.currentPageIndex + diff, this.getPageCount() - 1),
    );
  }

  ChangeSorting(column: string): void {
    if (this.sortingColumn == column) {
      this.sortingDirection = this.sortingDirection == "asc" ? "desc" : "asc";
    } else {
      this.sortingColumn = column;
      this.sortingDirection = "desc";
    }
  }

  FormatDate(date: string): string {
    return dayjs(date).format("DD.MM.YYYY HH:mm");
  }
}
