import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBars,
  faCog,
  faFilePdf,
  faSignOutAlt,
  faTimes,
  faTablet,
  faCode,
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faPen,
  faEdit,
  faTrash,
  faSpinner,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { faSquare, faCheckSquare } from "@fortawesome/free-regular-svg-icons";
import { App } from "vue";

library.add(
  faSignOutAlt,
  faCog,
  faBars,
  faFilePdf,
  faTablet,
  faTimes,
  faCode,
  faChevronRight,
  faChevronLeft,
  faUsers,
  faSpinner,
  faPen,
  faEdit,
  faTrash,
  faSquare,
  faCheckSquare,
  faChevronDown,
  faChevronUp,
);

export const injectFontAwesome = (app: App): void => {
  app.component("font-awesome-icon", FontAwesomeIcon);
};
