
import { Vue } from "vue-class-component";
export default class ColorDisplayExample extends Vue {
  get cssVars(): string {
    if (
      typeof this.$store.state.customer?.colorValue === "string" &&
      this.$store.state.customer?.colorValue.length == 7 &&
      this.$store.state.customer?.colorValue[0] == "#" &&
      this.$store.state.customer?.colorOverwriteActive == 1
    ) {
      return `--example-color: ${this.$store.state.customer?.colorValue};`;
    } else {
      return `--example-color: ${this.$store.state.customer?.originalColor};`;
    }
  }

  saveColorOverwrite(event: boolean): void {
    this.$store.commit("customer/setColorOverwriteActive", event);
    this.$store.dispatch("customer/save");
  }
}
